.premium__page__container{
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    padding-top: 32px;
}

.ant-tooltip-inner {
    color: black!important;
    background-color: white!important;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow, .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow, .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    border-bottom-color: green;
}

.sized{
    width: 800px;
    display: flex;
    flex-direction: column;
}

.premium__header{
    color: rgba(8, 12, 30, 1);
    font-size: 24px;
    font-family: Satoshi-Bold;
    padding-bottom: 18px;
}

.premium-stats__container{
    background-color: white;
    padding: 16px;
    border: 1px solid rgba(237, 241, 249, 1);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.circle{
    width: 56px;
    height: 56px;
    /* background-color: black; */
}
.statistics__circle{
    display: flex;
    align-items: center;
    gap: 8px;
}

.circle-info{
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.circle-active-words{
    padding-bottom: 0!important;
    font-size: 12px!important;
    font-family: Satoshi-Regular!important;
    color: #A6A8B0!important;
}

.current__plan>span{
    font-family: Satoshi-Medium!important;
}

.current__plan{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.current-plan-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    padding: 10px 15px!important;
    border-radius: 12px;
}

.current-plan-btn>span{
    color: white;
}

.circular-progress{
    /* --progress: 60; */
    --size: 250px;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 40px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    --dash: calc((var(--progress) * var(--circumference)) / 100);
    --dash-deep: calc((var(--progress-deep) * var(--circumference)) / 100);
}

.circular-progress circle {
    cx: var(--half-size);
    cy: var(--half-size);
    r: var(--radius);
    stroke-width: var(--stroke-width);
    fill: none;
    stroke-linecap: round;
  }

  .circular-progress circle.bg {
    stroke: rgba(238, 240, 242, 1);
  }

  .circular-progress circle.fg {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: rgba(158, 176, 255, 1);
  }
  .circular-progress circle.deep {
    transform: rotate(-90deg);
    transform-origin: var(--half-size) var(--half-size);
    stroke-dasharray: var(--dash-deep) calc(var(--circumference) - var(--dash-deep));
    transition: stroke-dasharray 0.3s linear 0s;
    stroke: #9A65CE;
  }

.plans{
    display: flex; 
    justify-content: space-between;
}

.plan__container{
    width: 384px;
    background-color: white;
    display: flex;
    justify-content: center;
    border: 1px solid rgba(237, 241, 249, 1);
    border-radius: 12px;
}

.prem-checkbox__container>span{
    /* font-family: Satoshi-Light!important; */
    color: rgba(92, 94, 100, 1);
}
.prem-checkbox__container{
    display: flex;
    justify-content: center;
    gap: 5px;
}

.plan__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.check__containers{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin-left: 56px;
    gap: 10px;
}
.container__type{
    color: rgba(166, 168, 176, 1);
    font-family: Satoshi-Bold!important;
    font-size: 26px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.container__type-price{
    color: rgba(8, 12, 30, 1);
    font-size: 34px;
    /* margin-top: 10px; */
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.plan__container-btn{
    border-radius: 8px;
    width: calc(100% - 2 * 30px);
    margin-top: 100px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
}

.mbt{
    margin-top: 135px;
}

.current__plan-widget{
    font-size: 16px;
    padding: 5px;
    background-color: rgba(247, 249, 251, 1);
    border-radius: 6px;
    border: 1px solid rgba(237, 241, 249, 1);
    color: rgba(8, 12, 30, 1);

}

.popular__container{
    background-color: rgba(124, 147, 247, 1);
    color: white;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-right: 15px;
    padding-left: 20px;
    border-radius: 0px 12px 12px 0px;
}

.popular__cotnainer-text{
    color: white;
    font-size: 16px;
}

.free-plan-btn{
    display: inline;
    border-radius: 8px!important;
}

.free-plan-btn>span{
    color: rgba(8, 12, 30, 1);
}

.sm__container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

.subscribe-modal__header{
    font-size: 32px;
    color: rgba(8, 12, 30, 1);
    text-align: center;
}

.subscribe-modal__content{
    text-align: center;
    width: 300px;
}

/* .input__info-subs{
    width: 260px;
} */

.sm__request{
    width: 100%;
    border-radius: 8px;
}

.popover__container{
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.popover__header{
    font-size: 12px;
    color: #A6A8B0;
}

.popover__content-words{
    color: #080C1E;
    font-size: 16px;
    font-family: Satoshi-Bold;
}

.circle-disabled-words{
    font-family: Satoshi-Medium!important;
    font-size: 24px;
    color: #080C1E;
}

.circle-disabled__words{
    color: #5C5E64;
    font-family: Satoshi-Medium!important;

}

.premium__header-container{
    display: flex;
    align-items: center;
    gap: 5px;
}

.premium__header-container>span{
    font-family: Satoshi-Medium;
    font-size: 12px;
    color: #A6A8B0;
}